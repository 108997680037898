import * as React from "react";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
// import Placeholder from "../components/Placeholder";
import News from "../components/News2";
import FounderSection from "../components/FounderSection";
import MissionSection from "../components/MissionSection";
import HomePopup from "../components/HomePopup";
import Seo from "../components/seo";
import NewsTicker from "../components/Newsticker";
import AccoladesSection from "../components/AccoladesSection";
import AdmissionEnquiry from "../components/AdmissionEnquiryForm";

const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title="Chinmaya Vidyalaya | CBSE School in Vasant Vihar | Delhi"
        description="Chinmaya Vidyalaya Vasant Vihar is among the best CBSE schools in Delhi under the aegis of the Chinmaya Mission."
        keywords="cbse schools in delhi,best schools in delhi,Chinmaya Vidyalaya Vasant Vihar,chinmaya vidyalaya, CBSE School in Vasant Vihar"
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
      />
      {/* <NewsTicker /> */}
      <AdmissionEnquiry />
      <HomePopup />
      <Hero />
      <AccoladesSection />
      {/* <News /> */}
      <FounderSection />
      <MissionSection />
    </Layout>
  );
};

export default IndexPage;
