import React, { useEffect, useState } from "react";
import axios from "axios";
import "./style.scss";

const formStatus = {
  filling: "FILLING",
  validation_error: "VALIDATION_ERROR",
  error: "ERROR",
  success: "SUCCESS",
  submitting: "SUBMITTING",
};

const AdmissionEnquiry = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobile: "",
    childAge: "",
    city: "",
  });

  const [showForm, setShowForm] = useState(false);

  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState(formStatus.filling);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    setTimeout(() => {
      setShowForm(true);
    }, 8000);
  }, []);

  const validate = () => {
    const newErrors = {};
    if (!formData.fullName.trim())
      newErrors.fullName = "Full name is required.";
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "A valid email is required.";
    if (!formData.mobile.trim() || !/^\d{10}$/.test(formData.mobile))
      newErrors.mobile = "Enter a valid 10-digit mobile number.";
    if (
      !formData.childAge ||
      isNaN(formData.childAge) ||
      formData.childAge <= 0
    )
      newErrors.childAge = "Enter a valid child age.";
    if (!formData.city.trim()) newErrors.city = "Address is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("SUBMITTING");
    if (validate()) {
      try {
        await axios.post(
          "https://chinmayavvdelhi.ac.in/api/public/admissions.php/enquiry-homepage",
          formData
        );

        setStatus(formStatus.success);
        setTimeout(() => {
          setStatus(formStatus.filling);
          setShowForm(false);
        }, 5000);

        setFormData({
          fullName: "",
          email: "",
          mobile: "",
          childAge: "",
          city: "",
        });
      } catch (e) {
        setStatus(formStatus.error);
      }
    } else {
      setStatus(formStatus.validation_error);
    }
  };

  return (
    <div
      className={`admissionenquiry ${showForm && "admissionenquiry--active"}`}
    >
      <button
        onClick={() => setShowForm(false)}
        className="admissionenquiry__close"
      >
        <i className="fa fa-times"></i>
      </button>
      <button
        className="admissionenquiry__enbutton"
        onClick={() => setShowForm(!showForm)}
      >
        Enquire Now
      </button>

      <h2>Admission Enquiry Form</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="fullName">Full Name</label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            placeholder="Enter full name"
          />
          {errors.fullName && (
            <small style={{ color: "red" }}>{errors.fullName}</small>
          )}
        </div>
        <div>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter email address"
          />
          {errors.email && (
            <small style={{ color: "red" }}>{errors.email}</small>
          )}
        </div>
        <div>
          <label htmlFor="mobile">Mobile</label>
          <input
            type="text"
            id="mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            placeholder="10 digit mobile number"
          />
          {errors.mobile && (
            <small style={{ color: "red" }}>{errors.mobile}</small>
          )}
        </div>
        <div>
          <label htmlFor="childAge">Child Age</label>
          <input
            type="number"
            id="childAge"
            name="childAge"
            value={formData.childAge}
            onChange={handleChange}
            placeholder="Enter child age"
          />
          {errors.childAge && (
            <small style={{ color: "red" }}>{errors.childAge}</small>
          )}
        </div>
        <div>
          <label htmlFor="city">Address</label>
          <input
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            placeholder="Enter Address"
          />
          {errors.city && <small style={{ color: "red" }}>{errors.city}</small>}
        </div>
        <button disabled={status == formStatus.submitting} type="submit">
          {status == formStatus.submitting ? "...Submitting" : "Submit"}
        </button>
        {status == formStatus.success && (
          <small style={{ color: "green" }}>
            Your enquiry has been submitted succesfully.
          </small>
        )}

        {status == formStatus.error && (
          <small style={{ color: "red" }}>
            Looks like something went wrong with the request. Please try again
            later.
          </small>
        )}
      </form>
    </div>
  );
};

export default AdmissionEnquiry;
