import React from "react";
import "./style.scss";
export default function HomePopup() {
  function onClose() {
    const popup = document.querySelector(".homepopup");
    popup.classList.remove("homepopup--active");
  }

  return (
    <div className="homepopup">
      <div className="homepopup__container">
        <button onClick={onClose} className="homepopup__close">
          <i className="fa fa-times"></i>
        </button>
        <a className="announcement_link" href="/registrations/">
          <div className="announcement">
            {/* <h1>CBSE RESULTS <span style={{}}>2021 - 2022</span></h1>
            <p>Congratulations to Class XII students. We celebrate the success of each child. You have done us proud.</p>
            <div className="students">

              <div className="student">
                <h3>SCIENCE</h3>
                <img src="https://chinmayavvdelhi.ac.in/assets/images/results/Priyanshi-Swain-XII-Chemistry-Biology.jpg"></img>
                <div style={{ marginTop: "10px" }}>PRIYANSHI SWAIN</div>
                <h4>94%</h4>
              </div>
              <div className="student">
                <h3>COMMERCE</h3>
                <img src="https://chinmayavvdelhi.ac.in/assets/images/results/Ishita-Gambhir-XII-Accountancy-BusinessStudies-Economics-English-Physical-Education.jpg"></img>
                <div style={{ marginTop: "10px" }}>ISHITA GAMBHIR</div>
                <h4>98.2%</h4>
              </div>
              <div className="student">
                <h3>HUMANITIES</h3>
                <img src="https://chinmayavvdelhi.ac.in/assets/images/results/Krishna-Agarwal-XII.png"></img>
                <div style={{ marginTop: "10px" }}>KRISHNA AGARWAL</div>
                <h4>96.8%</h4>
              </div>
            </div> */}
            {/* <img src="https://chinmayavvdelhi.ac.in/assets/images/nursery-lottery-jan-2023.jpeg"></img> */}
            {/* <img src="https://chinmayavvdelhi.ac.in/assets/images/admission-open-2023.jpeg"></img> */}
            <div className="teachers-award">
              {" "}
              {/* <h4>
                Visionary Leadership Honoured: <br />
                Principal Ms. Archana Soni Receives <br />
                Prestigious State Teachers' Award 2024
              </h4> */}
              <img
                alt=""
                src="https://chinmayavvdelhi.ac.in/assets/images/admissions-microsite/popup.jpg"
              ></img>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}
