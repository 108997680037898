import React from "react";
import { Link } from "gatsby";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";
import { useState } from "react";

export default function Hero() {
  const [imageIndex, setImageIndex] = useState(0);

  const heroDesktopData = [
    {
      img: "https://chinmayavvdelhi.ac.in/assets/images/hero/hero_desktop_5.jpg",
      text: `
      <span style="font-weight: 700; color:#DB5230">Admissions Open</span><br/>
      <span style="font-weight: 700; letter-spacing: 1px; font-size: 35px; color: #000000">Academic Session 2025 - 2026</span>`,
    },

    {
      img: "https://chinmayavvdelhi.ac.in/assets/images/hero/hero_desktop_6.jpg",
      text: `
      <span style="font-weight: 700; color:#43B7B4">Tune in for a</span><br/>
      <span style="font-weight: 700; letter-spacing: 1px;font-size: 35px; color: #182951">dialogue with a difference</span>`,
    },
    {
      img: "https://chinmayavvdelhi.ac.in/assets/images/hero/hero_desktop_1.jpg",
      text: `
      <span style="font-weight: 700; color:#FDD805">Ranked No. 3</span><br/>
      <span style="font-size: 35px">South West Delhi Leaders Category<br/>
      Times School Survey 2024</span>`,
    },
    {
      img: "https://chinmayavvdelhi.ac.in/assets/images/hero/hero_desktop_2.jpg",
      text: `
      <span style="font-weight: 700; color: #182951">Chinmaya Vision Programme</span><br/>
      <span style="color: black;">for holistic development</span>`,
    },

    {
      img: "https://chinmayavvdelhi.ac.in/assets/images/hero/hero_desktop_3.jpg",
      text: `
      <span style="font-weight: 700; color:#FDD805">Legacy of 40+ years</span><br/>
      of educational excellence`,
    },

    {
      img: "https://chinmayavvdelhi.ac.in/assets/images/hero/hero_desktop_4.jpg",
      text: `
      <span style="font-weight: 700; color: #D75431">Value-based approach</span><br/>
      <span style="color: black;">with signature activities`,
    },
  ];

  const heroMobileData = [
    {
      img: "https://chinmayavvdelhi.ac.in/assets/images/hero/hero_mobile_5.jpg",
      text: `
      <span style="font-weight: 700; color:#DB5230">Admissions Open</span><br/>
      <span style="font-size: 35px">Academic Session 2025-2026</span>`,
    },

    {
      img: "https://chinmayavvdelhi.ac.in/assets/images/hero/hero_mobile_6.jpg",
      text: `
      <span style="font-weight: 700; color:#43B7B4">Tune in for a</span><br/>
      <span style="font-size: 40px">dialogue with a difference</span>`,
    },
    {
      img: "https://chinmayavvdelhi.ac.in/assets/images/hero/hero_mobile_1.jpg",
      text: `
      <span style="font-weight: 700; color:#FDD805">Ranked No.4</span><br/>
      <span>South West Delhi Leaders Category<br/>
      Times School Survey 2023</span>`,
    },

    {
      img: "https://chinmayavvdelhi.ac.in/assets/images/hero/hero_mobile_2.jpg",

      text: `
      <span style="font-weight: 700; color:#FDD805">Legacy of 40+ years</span><br/>
      of educational excellence`,
    },

    {
      img: "https://chinmayavvdelhi.ac.in/assets/images/hero/hero_mobile_3.jpg",
      text: `
      <span style="font-weight: 700; color: #FDD805">Chinmaya Vision Programme</span><br/>
      <span style="color: white;">for holistic development</span>`,
    },
  ];

  const settings = {
    // dots: true,
    infinite: true,
    arrows: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const heroImages = [{ desktop: "", mobile: "" }];

  return (
    <section className="hero">
      <div className="hero__slider desktop">
        <Slider
          afterChange={(index) => {
            setImageIndex(index);
          }}
          {...settings}
        >
          {heroDesktopData.map((item, i) => (
            <div
              key={`hero-image-desktop-${i + 1}`}
              className="hero__slider__slide"
            >
              <div
                className="hero__slider__slide__text"
                dangerouslySetInnerHTML={{ __html: item.text }}
              ></div>
              <div className="hero__slider__slide__overlay"></div>
              <img
                alt={`hero image desktop ${i + 1}`}
                className="hero__img"
                src={item.img}
              ></img>
            </div>
          ))}
        </Slider>
      </div>
      <div className="hero__slider mobile">
        <Slider {...settings}>
          {heroMobileData.map((item, i) => (
            <div
              key={`hero-image-mobile-${i + 1}`}
              className="hero__slider__slide"
            >
              <div
                className="hero__slider__slide__text"
                dangerouslySetInnerHTML={{ __html: "" }}
              ></div>
              <div className="hero__slider__slide__overlay"></div>
              <img
                alt={`hero image desktop ${i + 1}`}
                className="hero__img"
                src={item.img}
              ></img>
            </div>
          ))}
        </Slider>
      </div>
      <div className="hero__content">
        <div className="hero__quote">
          <div className="hero__quote__buttons">
            <Link to="/registrations/" className="btn-white">
              Registrations
            </Link>
            <Link to="/circulars" className="btn-white">
              School Circulars
            </Link>
            {/* <Link to="/calendar/" className="btn-white">
              School Events
            </Link> */}
            <Link to="/hall-of-fame/" className="btn-white">
              Hall of Fame
            </Link>

            {/*  <a
              href="https://mimamsa.chinmayavvdelhi.ac.in/winners"
              target="__blank"
              className="btn-white"
            >
              Mimamsa 2024 Winners
            </a> */}
          </div>
        </div>
      </div>
    </section>
  );
}
