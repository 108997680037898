import React from "react";
import "./style.scss";
import { Link } from "gatsby";

import gurujiImage from "../../images/assets/guruji.png";

export default function FounderSection() {
  return (
    <section className="founder">
      <h2 className="heading--white">The Founder and Guide</h2>
      <div className="row founder__row">
        <img
          className="founder__img"
          alt="Gurudev Swami Chinmayananda"
          src={gurujiImage}
        ></img>

        <h3 className="founder__heading">Gurudev’s Message</h3>
        <div className="founder__text">
          <div>
            Pujya Gurudev Swami Chinmayananda was a sage, philosopher, thinker,
            writer and disciplinarian, all rolled into one. He believed that all
            education is fruitless, and mere labour, if it does not inculcate
            noble virtues in students. As he envisaged it, education was not
            only a load of information, but also the light of transformation. He
            envisioned that the reason for the dissonance among people around
            the world, was the absence of a manual for living.
            <br />
            <br />
            He believed that Vedanta, the universal science of life, was the
            spiritual birthright of every human being, and that its earnest
            study would result in tremendous growth and evolution. Advaita
            Vedanta, the ageless wisdom of universal oneness, is applicable to
            all, irrespective of background and class; ecumenical in approach,
            and with the remarkable ability to accommodate changing times
            without compromising its essence.
            <br />
            <br />
            He wished to build an institution, not an institute; a veritable
            movement that nourished souls, creating new generations of men and
            women of high integrity and great moral stature. Gurudev’s vision,
            enshrined in the Chinmaya Vision Programme, is the foundation on
            which the Chinmaya Educational Institutions stand tall today,
            nourishing the global Chinmaya family and the society at large.
            <br />
            <br />
            With more than 10,000 students children enrolling each year, and
            more than a million having graduated, the Chinmaya Education
            Movement has come a long way since inception. Many lamps have been
            lit, many hearts have been kindled…
          </div>

          <div className="founder__buttons">
            <Link to="/chinmaya-mission-trust/" className="btn-white">
              Chinmaya Mission
            </Link>
            <Link to="/gurudevs-journey" className="btn-white">
              Gurudev's Journey
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
